/**
 * @license
 *
 * Font Family: Clash Grotesk
 * Designed by: Indian Type Foundry
 * URL: https://www.fontshare.com/fonts/clash-grotesk
 * © 2022 Indian Type Foundry
 *
 * Font Styles:
 * Clash Grotesk Variable(Variable font)
 * Clash Grotesk Extralight
 * Clash Grotesk Light
 * Clash Grotesk Regular
 * Clash Grotesk Medium
 * Clash Grotesk Semibold
 * Clash Grotesk Bold
 *
*/

/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 700.0;
*
* available axes:

* 'wght' (range from 200.0 to 700.0)

*/

@font-face {
    font-family: 'ClashGrotesk';
    src: url('/fonts/clash-grotesk/ClashGrotesk-Regular.woff2') format('woff2'),
        url('/fonts/clash-grotesk/ClashGrotesk-Regular.woff') format('woff'),
        url('/fonts/clash-grotesk/ClashGrotesk-Regular.ttf') format('truetype');
    font-display: fallback;
    font-style: normal;
}

@font-face {
    font-family: 'ClashGrotesk';
    src: url('/fonts/clash-grotesk/ClashGrotesk-Light.woff2') format('woff2'),
        url('/fonts/clash-grotesk/ClashGrotesk-Light.woff') format('woff'),
        url('/fonts/clash-grotesk/ClashGrotesk-Light.ttf') format('truetype');
    font-weight: 300;
    font-display: fallback;
    font-style: normal;
}

@font-face {
    font-family: 'OrangeAvenue';
    src: url('/fonts/OrangeAvenue/OrangeAvenue-Regular.otf') format('woff2'),
        url('/fonts/OrangeAvenue/OrangeAvenue-Regular.woff') format('woff'),
        url('/fonts/OrangeAvenue/OrangeAvenue-Regular.ttf') format('truetype');
    font-display: fallback;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSauceOne';
    src: url('/fonts/open-sauce-one/OpenSauceOne-Regular.ttf') format('truetype');
    font-display: fallback;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSauceOne';
    src: url('/fonts/open-sauce-one/OpenSauceOne-Medium.ttf') format('truetype');
    font-weight: 500;
    font-display: fallback;
    font-style: normal;
}
