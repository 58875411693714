@import url('https://use.typekit.net/chb2vum.css');
@import url('./fonts.css');

:root {
    --black: #000000;
    --white: #ffffff;
    --orange: #D34319;
    --grey: #F7F7F7;
    --dark-grey: #17181a;

}

html {
    overflow-x: hidden;

}

// // @TEMP
// // Hide popup errors
nextjs-portal {
    display: none !important;
}

body {
    margin: 0;
    padding: 0;
    background: #ffffff;
    color: var(--dark-grey);
}

.c-scrollbar {
    z-index: 200;
}

* {
    font-family: inherit;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

.no-styles {
    font-size: inherit !important;
    line-height: inherit !important;
    font-weight: inherit !important;

    strong {
        font-weight: inherit !important;
    }
}

button {
    outline: none;
    background: transparent;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    color: inherit;
}

a {
    text-decoration: none;
    color: unset;
    cursor: pointer;

    &:visited {
        color: unset;
    }
}